import React from 'react';
import { Row, Col, Container } from 'react-bootstrap'
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components";

const ContainerGeneral = styled.section`
    position: relative;
    background-color: #fff;

    button{
        border-radius: 0;
        background-color: #1D2146 !important;
        border-color: #1D2146 !important;
        padding:10px 50px;
        cursor:pointer;
        z-index: 999 !important;
    }
`
const P = styled.p`
    text-align: center;
    color:#000;
    padding-top: 2%;
`
const H2 = styled.h2`
    text-align: center;
    color:#000;
    font-size: 3rem;
`
const H3 = styled.h3`
    text-align: center;
    color:#C1292F;
    font-size: 1.2rem;
`
const ContainerImage = styled.div`
    padding: 10px;
    border:solid 0.5px #ccc;
`
const ContainerInfo = styled(Container)`
    margin-top: -20rem;
`


const Portfolio = () => {
    return (
        <ContainerGeneral className="mb-5">
            <StaticImage src="../../../images/timon.png" />
            <ContainerInfo>
                <Row>
                    <Col xs={12}>
                        <H3>CONOCENOS</H3>
                        <H2>Objetivo</H2>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col lg={3} md={4} sm={6} xs={6} className="mt-3">
                        <ContainerImage>
                            <StaticImage src="../../../images/portafolio-01.png" />
                        </ContainerImage>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={6} className="mt-3">
                        <ContainerImage>
                            <StaticImage src="../../../images/portafolio-02.png" />
                        </ContainerImage>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={6} className="mt-3">
                        <ContainerImage>
                            <StaticImage src="../../../images/portafolio-03.png" />
                        </ContainerImage>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={6} className="mt-3">
                        <ContainerImage>
                            <StaticImage src="../../../images/portafolio-04.png" />
                        </ContainerImage>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={6} className="mt-3">
                        <ContainerImage>
                            <StaticImage src="../../../images/portafolio-05.png" />
                        </ContainerImage>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={6} className="mt-3">
                        <ContainerImage>
                            <StaticImage src="../../../images/portafolio-06.png" />
                        </ContainerImage>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={6} className="mt-3">
                        <ContainerImage>
                            <StaticImage src="../../../images/portafolio-07.png" />
                        </ContainerImage>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={6} className="mt-3">
                        <ContainerImage>
                            <StaticImage src="../../../images/portafolio-08.png" />
                        </ContainerImage>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xs="auto" className="mt-5">
                        <button type="button" className="btn btn-primary">VER</button>
                    </Col>
                </Row>
            </ContainerInfo>
        </ContainerGeneral>
    );
}

export default Portfolio;