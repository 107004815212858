import React from 'react';
import SEO from '../../structure/seo';
import Layout from '../../structure/layout';
import Index from '../../structure/pages/home/portfolio';


const Portfolio = () => {
    return (
        <Layout shadowNav={false}>
            <SEO title={'Portafolio'} />
            <Index />
        </Layout>
    );
}

export default Portfolio;